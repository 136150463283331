import { graphql } from 'gatsby';
import React, { Component } from 'react';
import styled from 'styled-components';
import ContactForm from '../components/ContactForm';
import Content from '../components/Content';
import Layout from '../components/Layout';

const Icons = styled('p')`
  a {
    display: inline-block;
    line-height: 1em;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  i::before {
    font-size: 0.75em;
    vertical-align: -15%;
    margin-right: 0.25em;
  }
`;

class Page extends Component {
  componentDidMount() {
    window.mr.maps.documentReady(window.jQuery);
  }
  render() {
    const {
      data: { page, site },
    } = this.props;
    return (
      <Layout page={page}>
        <section className="space--sm border--bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <Content content={page.html} />
              </div>
            </div>
          </div>
        </section>
        <section className="unpad ">
          <div className="map-container">
            <iframe
              title="Map"
              src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GOOGLE_API_KEY}&q=${site.siteMetadata.googleMapsQuery}&zoom=10`}
            />
          </div>
        </section>
        <section className="switchable">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h4>{site.siteMetadata.companyName}</h4>
                <address>
                  {site.siteMetadata.address.street1}
                  <br />
                  {site.siteMetadata.address.city},{' '}
                  {site.siteMetadata.address.state}{' '}
                  {site.siteMetadata.address.zip}
                </address>
                <hr />
                <Icons>
                  <a href={`mailto:${site.siteMetadata.email}`}>
                    <i className="icon icon--sm icon-Email" />
                    <span>{site.siteMetadata.email}</span>
                  </a>
                  <br />
                  <a href={`tel:${site.siteMetadata.phone.replace('-', '')}`}>
                    <i className="icon icon--sm icon-Phone-2" />
                    <span>{site.siteMetadata.phone}</span>
                  </a>
                </Icons>
                <hr />
                <div>
                  <Content content={page.frontmatter.description} />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <p>
                  If this is in regards to a position, please{' '}
                  <a href="/careers">apply online</a>.
                </p>
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Page;

export const query = graphql`
  query GetContactPage($id: String!) {
    site {
      siteMetadata {
        title
        description
        companyName
        email
        phone
        googleMapsQuery
        address {
          street1
          city
          state
          zip
        }
      }
    }
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        metaTitle
        metaDescription
        description
      }
    }
  }
`;
