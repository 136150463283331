import React, { Component } from 'react';
import classNames from 'classnames';

class ContactForm extends Component {
  state = {
    fields: {},
    loading: false,
  };
  componentDidMount() {
    window.mr.forms.documentReady(window.jQuery);
  }
  handleEncode = (data) => {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  };
  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'file':
        value = target.files[0];
        break;
      default:
        value = target.value;
    }
    this.setState({ fields: { ...this.state.fields, [name]: value } });
  };
  handleSubmit = async (e) => {
    const form = e.target;
    // Use Stack's form validation
    if (mr.forms.validateFields(form) === 1) {
      e.preventDefault();
      return;
    }
    // Stop loading
    this.setState({ loading: true });
    // Send form data
    fetch(process.env.GATSBY_FORM_ENDPOINT, {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: this.handleEncode({
        _template: 'contact-notification',
        ...this.state.fields,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error || res.statusCode !== 200) {
          throw new Error(res.error || res.message);
        }
        // Reset form
        this.setState({ fields: {}, loading: false });
        // Reset file input field
        form.resume = null;
        // Show success notification
        // console.log(`[Form] ${res.message}`);
        const formError = jQuery('body').find('.form-error');
        const formSuccess = jQuery('body').find('.form-success');
        mr.forms.showFormSuccess(formSuccess, formError, 1000, 5000, 500);
      })
      .catch((error) => {
        this.setState({ loading: false });
        const formError = jQuery('body').find('.form-error');
        const formSuccess = jQuery('body').find('.form-success');
        // If error text was returned, put the text in the .form-error div and show it.
        // Keep the current error text in a data attribute on the form
        formError.attr('original-error', formError.text());
        // Show the error with the returned error text.
        formError.text(error).stop(true).fadeIn(1000);
        formSuccess.stop(true).fadeOut(1000);
      });
    e.preventDefault();
  };
  render() {
    const { loading, fields } = this.state;
    return (
      <form
        name="contact"
        method="post"
        autoComplete="off"
        className="text-left form-email row"
        data-success="Thanks for your inquiry, we'll be in touch shortly."
        data-error="Please fill in all fields correctly."
        onSubmit={this.handleSubmit}>
        <span hidden>
          <label>
            Don’t fill this out:
            <input
              name="_honeypot"
              type="text"
              value={fields._honeypot || ''}
              onChange={this.handleChange}
            />
          </label>
        </span>
        <div className="col-md-6 col-12">
          <label>Your Name:</label>
          <input
            type="text"
            name="name"
            className="validate-required"
            value={fields.name || ''}
            onChange={this.handleChange}
          />
        </div>
        <div className="col-md-6 col-12">
          <label>Email Address:</label>
          <input
            type="email"
            name="email"
            className="validate-required validate-email"
            value={fields.email || ''}
            onChange={this.handleChange}
          />
        </div>
        <div className="col-md-12 col-12">
          <label>Message:</label>
          <textarea
            rows="6"
            name="message"
            className="validate-required"
            value={fields.message || ''}
            onChange={this.handleChange}
          />
        </div>
        <div className="col-md-5 col-lg-4 col-6">
          <button
            type="submit"
            className={classNames('btn btn--primary type--uppercase', {
              'btn--loading': loading,
            })}>
            Send Message
          </button>
        </div>
      </form>
    );
  }
}

export default ContactForm;
